import getSignalR from 'core/signalR';
import {
  SuffixEndpoints,
} from 'core/signalR/types';
import React, {
  useEffect,
} from 'react';
import type {
  CallbackFunction,
  ParamWithCallBackFunction,
} from 'types/commonTypes';
import {
  getSubscribeEventNameBy,
  subscribeAllEventName,
  subscriptionPrefix,
} from 'core/signalR/helpers';
import isNil from 'lodash/isNil';
import {
  signalRConfig,
} from 'config/signalRConfig';
import {
  isEmptyString,
} from 'utils/misc';
import useUserInfo from 'pages/Dashboard/hooks/useUserInfo';

function useOrganizationId(): string | undefined {
  const user = useUserInfo();

  return user?.organization?.organizationId?.toString();
}

function useGetFinalEventId(eventId: string): string {
  const organizationId = useOrganizationId();

  return isEmptyString(organizationId)
    ? ''
    : `${subscriptionPrefix}${organizationId}-${eventId}`;
}

/**
 * Subscribes to a SignalR endpoint and manages the subscription lifecycle.
 *
 * @param eventId - the unique event id based on subscribe (listen) and unsubscribe (unmount)
 * @param {SuffixEndpoints} endPointSuffix - the suffix of the SignalR endpoint
 * @param {ParamWithCallBackFunction} subscribeCallback -
 *  the callback function for subscription
 * @param {React.DependencyList} [dependencies] - the optional list of dependencies
 * @param {CallbackFunction} [unsubscribeCallback] -
 *  the optional callback function for unsubscription
 * @param isSubscribeOnce
 */
/**
 * @deprecated
 * use useSignalREffect from views/EMR/hooks instead
 */
export function useSignalRSubscribeByEventId(
  endPointSuffix: SuffixEndpoints,
  eventId: string,
  subscribeCallback: ParamWithCallBackFunction,
  dependencies?: React.DependencyList,
  unsubscribeCallback?: CallbackFunction,
  isSubscribeOnce: boolean = false,
): void {
  dependencies = dependencies ?? [eventId];
  const organizationId = useOrganizationId();

  useEffect(() => {
    if (signalRConfig.debugging.isEnabledOnSubscribe) {
      console.warn(`useSignalRSubscribeByEventId to event id : ${endPointSuffix} - ${eventId} - isOnce ${isSubscribeOnce}`);
    }

    if (signalRConfig.debugging.isSkipAllSubscription) {
      console.warn(`useSignalRSubscribeByEventId to skip - event :${eventId} for config`);

      return () => {
      };
    }

    if (isEmptyString(eventId)) {
      console.trace(`useSignalRSubscribeByEventId to all ${endPointSuffix} -- event id is empty, Organization id empty : `, organizationId);

      return () => {
      };
    }

    if (signalRConfig.debugging.isEnabledOnSubscribe) {
      console.warn(`useSignalRSubscribeByEventId : ${endPointSuffix} - ${eventId} - isOnce ${isSubscribeOnce} -- Running`);
    }

    const signalRConnection = getSignalR(endPointSuffix);

    (async () => {
      const resolvedSignalR = await signalRConnection;

      if (isEmptyString(eventId) || eventId.endsWith('-')) {
        console.trace(`AppLevel : useSignalRSubscribeByEventId ${endPointSuffix} - ${eventId} - isOnce ${isSubscribeOnce} -- skipping event:`, eventId);

        return;
      }

      if (signalRConfig.debugging.isAppLevelSubscription) {
        console.warn(`AppLevel : useSignalRSubscribeByEventId ${endPointSuffix} - ${eventId} - isOnce ${isSubscribeOnce} -- subscribing.`);
      }

      if (isSubscribeOnce) {
        resolvedSignalR.subscribeOnce(eventId, subscribeCallback);
      } else {
        resolvedSignalR.subscribe(eventId, subscribeCallback);
      }
    })();

    return () => {
      (async () => {
        const resolvedSignalR = await signalRConnection;

        resolvedSignalR.safeUnsubscribe(
          eventId,
          unsubscribeCallback,
        );

        if (signalRConfig.debugging.isAppLevelSubscription) {
          console.warn(`AppLevel : useSignalRSubscribeByEventId ${endPointSuffix} - ${eventId} - isOnce ${isSubscribeOnce} -- unsubscribing / exiting.`);
        }
      })();
    };
  }, dependencies);
}

/**
 * @deprecated
 * use useSignalREffect from views/EMR/hooks instead
 */
export function useSignalRSubscribeToAll(
  endPointSuffix: SuffixEndpoints,
  subscribeCallback: ParamWithCallBackFunction,
  dependencies?: React.DependencyList,
  unsubscribeCallback?: CallbackFunction,
  isSubscribeOnce: boolean = true,
): void {
  const finalEventId = useGetFinalEventId(subscribeAllEventName);

  useSignalRSubscribeByEventId(
    endPointSuffix,
    finalEventId,
    subscribeCallback,
    dependencies,
    unsubscribeCallback,
    isSubscribeOnce,
  );
}

/**
 * @deprecated
 * use useSignalREffect from views/EMR/hooks instead
 */
export function useSignalRSubscribeOnce(
  endPointSuffix: SuffixEndpoints,
  subscribeCallback: ParamWithCallBackFunction,
  recordId?: string | number,
  dependencies?: React.DependencyList,
  unsubscribeCallback?: CallbackFunction,
): void {
  if (!isNil(recordId)) {
    if (signalRConfig.debugging.isEnabledOnSubscribe) {
      console.warn(`useSignalRSubscribeOnce to record : ${endPointSuffix} - ${recordId} - once`);
    }

    const eventId = getSubscribeEventNameBy(recordId);
    const finalEventId = useGetFinalEventId(eventId);

    dependencies = dependencies ?? [eventId];

    useSignalRSubscribeByEventId(
      endPointSuffix,
      finalEventId,
      subscribeCallback,
      dependencies,
      unsubscribeCallback,
      true,
    );
  }
}

/**
 * @deprecated
 * use useSignalREffect from views/EMR/hooks instead
 */
export function useSignalRSubscribeToAllOnce(
  endPointSuffix: SuffixEndpoints,
  subscribeCallback: ParamWithCallBackFunction,
  dependencies?: React.DependencyList,
  unsubscribeCallback?: CallbackFunction,
): void {
  if (signalRConfig.debugging.isEnabledOnSubscribe) {
    console.warn(`useSignalRSubscribeToAllOnce to all ${endPointSuffix} once called`);
  }
  const finalEventId = useGetFinalEventId(subscribeAllEventName);

  useSignalRSubscribeByEventId(
    endPointSuffix,
    finalEventId,
    subscribeCallback,
    dependencies,
    unsubscribeCallback,
    true,
  );
}
