import React, {
  lazy,
} from 'react';
import {
  RouteObject,
} from 'react-router-dom';
import LazyLoadComponent from 'core/components/LazyLoadComponent';

export const chartsRoutes: RouteObject[] = [{
  path: '/charts/:id/:tab',
  element: (
    <LazyLoadComponent
      Component={lazy(() => import('./pages/Details'))}
    />
  ),
}, {
  path: '/charts/:id',
  element: (
    <LazyLoadComponent
      Component={lazy(() => import('./pages/Details'))}
    />
  ),
}];
