import dayjs from 'utils/dayjs';
import {
  isEmptyString,
} from 'utils/misc';
import {
  WaitTimeType,
} from 'pages/Dashboard/pages/Encounters/pages/List/components/PatientRow/WaitTime';

export const getWaitTime = (patientEnteredInOfficeTime: string): WaitTimeType | null => {
  if (isEmptyString(patientEnteredInOfficeTime)) return null;

  const hours: number = dayjs().diff(patientEnteredInOfficeTime, 'hours');
  const minutes: number = dayjs().diff(patientEnteredInOfficeTime, 'minutes') - hours * 60;

  return {
    hours,
    minutes,
  };
};

export async function sleep(ms: number): Promise<void> {
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, ms);
  });
}
