import React, {
  ReactNode,
  useMemo,
  MouseEvent,
} from 'react';
import clsx from 'clsx';
import keyBy from 'lodash/keyBy';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select, {
  SelectChangeEvent,
} from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import ToolTip from 'core/components/Tooltip';
import Pill from 'core/components/Multiselect/Pill';

type Props<T> = {
  value: string[];
  onChange: (e: string[]) => void;
  options: T[];
  renderOption?: (option: T) => ReactNode | string;
  getItemLabel?: (option: T) => string;
  getItemKey?: (option: T) => string;
  placeholder?: string;
  className?: string;
  controlClassName?: string;
  disabled?: boolean;
  error?: boolean;
  id: string;
  includeAll?: boolean;
  allCaption?: string;
  hasClear?: boolean;
};

const defaultOptionResolver = (option: any) => (option as string);

type Option = {
  label: string;
  key: string;
};

type OptionListTooltipProps = {
  options: Option[];
  handleRemove?: (key?: string | null) => void;
};

function OptionListTooltip({ options, handleRemove }: OptionListTooltipProps) {
  return (
    <div className="flex flex-col space-y-2 p-2">
      {options.map((option) => (
        <Pill
          id={option?.key}
          label={option?.label}
          handleRemove={handleRemove}
        />
      ))}
    </div>
  );
}

export default function MultiSelect<T>({
  onChange,
  value = [],
  options = [],
  renderOption = defaultOptionResolver,
  getItemLabel = defaultOptionResolver,
  getItemKey = defaultOptionResolver,
  placeholder,
  className,
  controlClassName,
  disabled,
  error,
  includeAll,
  allCaption = 'All',
  hasClear = false,
  id,
}: Props<T>) {
  const classes = clsx(
    'flex flex-col space-y-3 relative',
    className,
    { 'has-error': error },
  );
  const selectClasses = clsx(
    'populate-select !rounded',
    controlClassName,
    { disabled },
  );
  const remainder = value?.slice(1);
  const optionMap = useMemo(() => keyBy(options, getItemKey), [options, getItemKey]);
  const checked = useMemo(() => new Set<string>(value), [value]);
  const allChecked = useMemo(() => (
    options.length > 0 && options.every((option) => checked.has(getItemKey(option)))
  ), [checked, options]);

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    const { target: { value } } = e;
    onChange(value as string[]);
  };

  const handleRemove = (key?: number | string | null) => {
    const index = value.findIndex(
      (item) => getItemKey(optionMap[item]) === key,
    );

    onChange([
      ...value.slice(0, index),
      ...value.slice(index + 1),
    ]);
  };

  return (
    <div className={classes}>
      <Select<string[]>
        id={id}
        className={selectClasses}
        multiple
        value={value}
        onChange={handleChange}
        displayEmpty
        MenuProps={{
          PopoverClasses: {
            paper: '!w-56',
          },
        }}
        renderValue={() => (
          <Box className="flex flex-wrap space-x-1">
            {value.length > 0 && !allChecked && (
              <>
                <Pill
                  id={getItemKey(optionMap[value[0]])}
                  label={getItemLabel(optionMap[value[0]]) ?? value[0]}
                  handleRemove={handleRemove}
                />
                {value.length > 1 && (
                  <ToolTip
                    title={(
                      <OptionListTooltip
                        options={remainder.map((optionKey) => ({
                          label: getItemLabel(optionMap[optionKey]) ?? optionKey,
                          key: getItemKey(optionMap[optionKey]),
                        }))}
                        handleRemove={handleRemove}
                      />
                    )}
                    isInteractive
                  >
                    <span className="!cursor-pointer !pointer-events-auto">
                      <Pill label={`+${remainder.length}`} />
                    </span>
                  </ToolTip>
                )}
              </>
            )}
            {allChecked && <Chip label={allCaption} className="!h-auto !p-0.25 !text-xs" />}
            {value.length === 0 && <div className="font-semibold">{placeholder}</div>}
          </Box>
        )}
      >
        {includeAll && (
          <MenuItem
            disabled={options.length === 0}
            className={clsx('!p-0 !bg-transparent !text-xs', { 'Mui-selected': allChecked })}
            onClick={(e: MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              onChange((allChecked ? [] : options).map(getItemKey));
            }}
          >
            <Checkbox
              checked={allChecked}
              className={clsx('!p-1.5', {
                '!text-blue-600': allChecked,
              })}
            />
            Select All
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={getItemKey(option)}
            className="!p-0 !bg-transparent !whitespace-pre-wrap"
            value={getItemKey(option)}
          >
            <Checkbox
              checked={checked.has(getItemKey(option))}
              className={clsx('!p-1.5', {
                '!text-blue-600': checked.has(getItemKey(option)),
              })}
            />
            {renderOption(option)}
          </MenuItem>
        ))}
        {hasClear && (
          <MenuItem
            className="!py-2"
            disabled={value.length === 0}
            onClick={(e: MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              onChange([]);
            }}
          >
            Clear All
          </MenuItem>
        )}
      </Select>
    </div>
  );
}
