import {
  signalRConfig,
} from 'config/signalRConfig';
import {
  baseApiUrl,
  environment,
} from 'env';
import {
  isEmptyString,
} from 'utils/misc';

const envMapping: Readonly<Record<string, string>> = Object.freeze({
  local: 'dev',
  localQa: 'qa',
  test: 'qa',
  qa: 'qa',
  prod: 'prod',
});

const getSignalEnvName = (): string => {
  if (environment in envMapping) {
    return envMapping[environment];
  }

  throw new Error('invalid environment');
};

export const signalREnvName = getSignalEnvName();
const endpointPrefix = `${baseApiUrl}/${signalRConfig.signalRPrefix}`;
export const subscriptionPrefix = `${signalREnvName}-`;
export const subscribeAllEventName = 'update-all';
const subscribeUpdateEventName = 'update';

export function getFinalEndpointUrl(endpoint: string): string {
  return `${endpointPrefix}/${subscriptionPrefix}${endpoint}`;
}

export function getSubscribeEventNameBy(
  id?: string | number,
): string {
  return isEmptyString(id) || id === 0
    ? ''
    : `${subscribeUpdateEventName}-${id}`;
}
