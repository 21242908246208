import {
  Value as DateRangeParam,
} from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';
import compact from 'lodash/compact';
import isNil from 'lodash/isNil';
import dayjs, {
  Dayjs,
} from 'utils/dayjs';
import {
  pluralize,
} from 'utils/string';

type DateParam = number | string | Date | Dayjs;

const _formatDate = (
  format: string,
  date: DateParam,
  keepLocal: boolean = true,
) => dayjs(date).tz(undefined, keepLocal).format(format);

export const formatDate = _formatDate.bind(null, 'M/D/YYYY');
export const formatDateTime = _formatDate.bind(null, 'h:mma M/D/YYYY');
export const formatTime = _formatDate.bind(null, 'h:mma');
export const formatDateOnly = _formatDate.bind(null, 'YYYY-MM-DD');
export const formatDateTimeOffset = _formatDate.bind(null, '');
export const formatDay = _formatDate.bind(null, 'ddd D');
export const formatISODateTime = _formatDate.bind(null, 'YYYY-MM-DDTHH:mm:ss');

type DateRange = Date[];
export const getDateRangeFromStringParam = (param: string): DateRange => {
  const [from, to] = param.split('--');
  return [
    dayjs(from).startOf('day').toDate(),
    dayjs(to).endOf('day').toDate(),
  ];
};

const timeUnits: string[] = ['hour', 'minute'];
export function getTimeDifferenceString(from: Dayjs, to: Dayjs): string {
  const timeDiff = [
    to.diff(from, 'hours'),
    to.diff(from, 'minutes') % 60,
  ];

  const timeValues = timeDiff.map((diffValue, index) => (
    diffValue > 0 ? `${pluralize(timeUnits[index], diffValue)}` : null
  ));

  return `(${compact(timeValues).join(' ')})`;
}

export function getTimeRangeString(from: Dayjs, to: Dayjs): string {
  return `${formatTime(from)} - ${formatTime(to)}`;
}

export function getDateRangeString(from: Dayjs, to: Dayjs): string {
  return `${formatDate(from)}${from.isSame(to, 'day') ? '' : ` - ${formatDate(to)}`}`;
}

export function isValidDateRange(range: string | Date | DateRangeParam | null) {
  return Array.isArray(range)
          && !isNil(range)
          && range.length === 2
          && range.every((date) => dayjs(date).isValid());
}
