import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import {
  HubConnection,
} from '@microsoft/signalr';
import {
  getSignalRHubConnection,
  Hub,
} from 'core/signalR';

type SignalRHubContextProps = {
  getHubConnection: (hubName: Hub) => Promise<HubConnection | null | undefined>;
};

export const SignalRHubContext = createContext<SignalRHubContextProps>({
  getHubConnection: () => Promise.resolve(null),
});

const hubs = new Map<Hub, HubConnection>();

export default function SignalRHubProvider({
  children,
}: PropsWithChildren) {
  const getHubConnection = useCallback(async (hubName: Hub) => {
    if (!hubs.has(hubName)) {
      const connection = getSignalRHubConnection(hubName);
      await connection.start();
      hubs.set(hubName, connection);
    }
    return hubs.get(hubName);
  }, []);

  const value = useMemo(() => ({
    getHubConnection,
  }), [getHubConnection]);

  useEffect(() => () => {
    Array.from(hubs.values()).forEach((hubConnection) => {
      hubConnection?.stop();
    });
    hubs.clear();
  }, []);

  return (
    <SignalRHubContext.Provider value={value}>
      {children}
    </SignalRHubContext.Provider>
  );
}
