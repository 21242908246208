export enum MediaCategory {
  Xray = 'Xray',
  OtherProviderNote = 'OtherProviderNote',
  InsuranceCard = 'InsuranceCard',
  LabReport = 'LabReport',
  MedicalRecords = 'MedicalRecords',
  VisitNoteImage = 'VisitNoteImage',
  ProfilePicture = 'ProfilePicture',
  FinalizedVisitNote = 'FinalizedVisitNote',
  Identification = 'Identification',
  ConsentForms = 'ConsentForms',
  MedicalNotes = 'MedicalNotes',
  RadiologyImages = 'RadiologyImages',
  EOB = 'EOBDoc',
}

export enum MediaOriginatorType {
  Org = 'Org',
  Patient = 'Patient',
  Billing = 'Billing',
}

export type MediaCategoryType = {
  label: string;
  value: string;
  group: string;
};

export const imagesTypes = [{
  label: 'MRI',
  value: 'MRI',
}, {
  label: 'X - Ray',
  value: 'Xray',
}, {
  label: 'CT Scan',
  value: 'CTScan',
}, {
  label: 'Ultrasound',
  value: 'Ultrasound',
}];

export const reportTypes = [{
  label: 'Lab Report',
  value: 'LabReport',
}, {
  label: 'EMG',
  value: 'EMG',
}, {
  label: 'Pathology',
  value: 'Pathology',
}, {
  label: 'Operative Report',
  value: 'OperativeReport',
}];

export const legalDocsTypes = [{
  label: 'Personal Injury Paperwork',
  value: 'PersonalInjuryPaperwork',
}, {
  label: 'Worker`s Compensation Paperwork',
  value: 'WorkersCompensationPaperwork',
}, {
  label: 'Car accident Paperwork',
  value: 'CarAccidentPaperwork',
}, {
  label: 'Requested Authorization',
  value: 'RequestedAuthorization',
}, {
  label: 'Approved Authorization',
  value: 'ApprovedAuthorization',
}, {
  label: 'Eligibility',
  value: 'Eligibility',
}, {
  label: 'PPO Verification',
  value: 'PPOVerification',
}];

export const referralDocsTypes = [{
  label: 'Referral',
  value: 'Referral',
}];

export const identificationDocsTypes = [{
  label: 'Insurance Card',
  value: 'InsuranceCard',
}, {
  label: "Driver's License",
  value: 'Identification',
}];

export const noteTypes = [{
  label: 'Other provider note',
  value: 'OtherProviderNote',
}, {
  label: 'Consult note',
  value: 'ConsultNote',
}];

export const otherTypes = [{
  label: 'Lab Slip',
  value: 'LabSlip',
}, {
  label: 'Consent',
  value: 'Consent',
}, {
  label: 'Prescription',
  value: 'Prescription',
}, {
  label: 'Bill Slip',
  value: 'BillSlip',
}, {
  label: 'Appeal',
  value: 'Appeal',
}, {
  label: 'OARRS/KASPER',
  value: 'OarrsKasper',
}, {
  label: 'Other',
  value: 'Other',
}];

export const mediaCategoryGroupings = [
  { group: 'Image', values: imagesTypes },
  { group: 'Report', values: reportTypes },
  { group: 'Legal Document', values: legalDocsTypes },
  { group: 'Referral', values: referralDocsTypes },
  { group: 'Notes', values: noteTypes },
  { group: 'Identification', values: identificationDocsTypes },
  { group: 'Other', values: otherTypes },
];
