import {
  AppointmentTypeSettingDTO,
} from 'dtos/appointmentTypeSettingDTO';
import compact from 'lodash/compact';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import zipObject from 'lodash/zipObject';
import {
  colorClasses,
} from 'pages/Dashboard/pages/Encounters/hooks';
import {
  isEmptyString,
} from 'utils/misc';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import {
  AppointmentTypes,
  AppointmentTypeSettings,
} from 'views/EMR/Settings/Appointments/types';

export function assignAppointmentTypeColors(
  appointmentTypes: string[],
): Record<string, string> {
  return {
    ...zipObject(
      appointmentTypes,
      appointmentTypes.map((type, index) => colorClasses[index % appointmentTypes.length]),
    ),
    'Time Blocked': '!bg-rose-300',
  };
}

export function normalizeAppointmentTypeSettings(
  data: AppointmentTypeSettingDTO[],
): AppointmentTypeSettings[] {
  const appointmentTypes = data?.filter((item) => item?.appointmentType !== 'Time Blocked');
  const colors = assignAppointmentTypeColors(
    compact(map(appointmentTypes, 'appointmentType')),
  );

  return appointmentTypes?.map((item) => ({
    ...item,
    color: colors[item?.appointmentType ?? ''],
  }));
}

export function normalizeAppointmentTypes(
  data: AppointmentTypeSettingDTO[],
): AppointmentTypes {
  const appointmentTypes = compact(map(data, 'appointmentType')).filter((appointmentType) => appointmentType !== 'Time Blocked');
  const durationByType = mapValues(
    keyBy(data, 'appointmentType'),
    (item) => item?.defaultDurationInMinutes ?? 15,
  );

  return { appointmentTypes, durationByType };
}

export function getSectionLabelByName(sectionName: string = '') {
  return startCase(sectionName?.replace('Template', ''));
}

export const EMPTY_CKE_TEXT = '<p><br data-cke-filler="true" /></p>';
const FIELD_PATTERN = /\$\("([\w|\s|\-|_|?|\\]+)":(\d+)([[|\]|"|\w|\s|_|\-|,|?|\\]*)\)/gi;
export function isEmptyRichText(content: string = '') {
  return content === EMPTY_CKE_TEXT;
}

function replaceWithField(match: string, title: string, id: string, selection: string) {
  const selected = JSON.parse(selection ?? '[]');
  return `<span class="__populate__-field" data-field-id="${id}" data-field-title="${title}" data-field-selection="${selection.replaceAll('"', '&quot;')}">${title}${selected.length > 0 ? ` [${selected.join(', ')}]` : ''}</span>`;
}

function replaceWithFieldValues(match: string, title: string, id: string, selection: string) {
  const selected = JSON.parse(selection ?? '[]');
  return (selected ?? []).join(', ');
}

export function normalizeTextWithFields(content: string = '') {
  return content.replace(FIELD_PATTERN, replaceWithFieldValues);
}

export function normalizeContent(content: string = '') {
  return isEmptyString(content)
    ? EMPTY_CKE_TEXT
    : (content ?? '')
      .split('\n')
      .map((paragraph) => (
        isEmptyString(paragraph)
          ? '<p>&nbsp;</p>'
          : `<p>${paragraph.replaceAll(String.fromCharCode(160), '&nbsp;')
            .replace(FIELD_PATTERN, replaceWithField)}</p>`))
      .join('');
}

export function serializeContent(htmlContent: string = '') {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const value = Array.from(doc.body.children)
    .map((paragraph) => Array.from(paragraph.childNodes)
      .map((node) => {
        const {
          fieldId,
          fieldTitle,
          fieldSelection,
        } = (node as HTMLElement)?.dataset ?? {};
        const text = node.nodeName === 'BR' ? '\n' : node.textContent;
        return node.nodeName === 'SPAN' && (node as Element).classList.contains('__populate__-field')
          ? `$("${fieldTitle}":${fieldId}${fieldSelection})`
          : text;
      }).join(''))
    .join('\n');
  return isEmptyString(value) ? null : value;
}
